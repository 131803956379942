import { lazy } from 'react';

// project import
import Loadable from 'component/Loadable';

import { P_AUTH_LOGIN } from 'config/service-url';
import lazyRetry from 'util/lazy-retry';

// service page
const Find = Loadable(lazy(() => lazyRetry(() => import('page/auth/Find'))));
const Login = Loadable(lazy(() => lazyRetry(() => import('page/auth/Login'))));
const Logout = Loadable(lazy(() => lazyRetry(() => import('page/auth/Logout'))));
const UpdatePassword = Loadable(lazy(() => lazyRetry(() => import('page/auth/UpdatePassword'))));

////
const rtFind = 'rtAuthFindLogin';
const rtLogin = 'rtAuthLogin';
const rtLogout = 'rtAuthLogout';
const rtUpdatePassword = 'rtAuthUpdatepassword';

/**
 *
 */
export function getLoginUserMenu(loginUser) {
    if (!loginUser) {
        window.location.href = P_AUTH_LOGIN;
        return [];
    }

    const loginUserMenu = loginUser.aptId 
        ? [
            { id: '1', pid: '0', order: '1', text: `${loginUser.name}` },
        ] : [
            { id: '1', pid: '0', order: '1', text: `${loginUser.name}` },
            {
                id: '1_1',
                pid: '1',
                order: '1',
                text: '비밀번호 변경',
                link: `?rr=${rtUpdatePassword}`,
            },
            {
                id: '1_2',
                pid: '1',
                order: '2',
                text: '로그아웃',
                link: `?rr=${rtLogout}`,
            },
        ];


    return loginUserMenu;
}

/**
 *
 */
export function getAuthRoot() {
    const authRootList = [
        { root: rtFind, component: Find },
        { root: rtLogin, component: Login },
        { root: rtLogout, component: Logout },
        { root: rtUpdatePassword, component: UpdatePassword },
    ];


    return authRootList;
}

/**
 *
 */
export default getLoginUserMenu;
