import { getDprtFromPath, getLoginUser } from 'config/global';

/**
 * 서버 설정과 일치 시켜야 함
 */

/**
 * 사용자 역할
 * 한 명의 사용자는 여러개의 역할을 가질 수 있음
 */
export const roles = {
    // 전체 시스템 관련 역할
    sysMaster: 'sys/master', // 전체 시스템 관리자. trustMaster 등록 
    // sysHrMaster: 'sys/hr/master', // HR 시스템 관리자. HR 시스템 전체 설정 변경

    // 위탁사 관련 역할 
    trustMaster: 'trust/master', // 위탁사 시스템 관리자.  위탁사 소속 직원 등록  및 권한 관리
    // trustHrMaster: 'trust/hr/master', // 위탁사 HR 시스템 관리자.  위탁사 소속 직원의 HR 관련 권한 관리
    trustHrUpdater: 'trust/hr/updater', // 위탁사 인사 담당자. 위탁사의 인사 관련 데이터의 생성, 변경, 조회 가능
    trustHrSalary: 'trust/hr/salary', // 위탁사 소속 부서 및 하위 조직의 급여 정보 조회 가능
    trustHrManager: 'trust/hr/manager', //  위탁사 소속 부서 및 하위 조직의 인사 정보를 조회할 수 있음. 급여 정보 조회 불가
    trustHrEmp: 'trust/hr/employee', // 본인의 인사 정보를 조회할 수 있음

    // 단지 관련 역할
    aptMaster: 'apt/master', // 단지 시스템 관리자.  이 역할 대상자는 apt manual에서 관리
    // aptHrMaster: 'apt/hr/master', // 단지 HR 시스템 관리자.  단지 소속 직원의 HR 관련 권한 관리.
    aptHrUpdater: 'apt/hr/updater', // 단지 인사 담당자. 인사 정보의 생성, 변경, 조회 가능
    aptHrSalary: 'apt/hr/salary', // 단지 소속 부서 및 하위 조직의 급여 정보 조회 가능
    aptHrManager: 'apt/hr/manager', // 단지 소속 부서 및 하위 조직의 인사 정보를 조회할 수 있음. 급여 정보 조회 불가
    aptHrEmp: 'apt/hr/employee', //  단지 근무자 본인의 인사 정보를 조회할 수 있음 
};

/**
 * 로그인 사용자의 조직 관련 역할 조회
 * @param {object[]|null} [dprtList = null] - 
 * @param {string} dprtLis[].dprtPath - 조직장, 인사 담당자, aptMaster 여부를 판단할 조직 경로 
 * @return {object} - 각 역할에 대한 boolean
 */
export const getUserRole = (dprtList = null) => {
    const userRole = {
        sysMaster: false,
        // sysHrMaster: false,
        trustMaster: false,
        // trustHrMaster: false,
        trustHrUpdater: false,
        trustHrSalary: false,
        trustHrManager: false,
        trustHrEmp: false,
        aptMaster: false,
        // aptHrMaster: false,
        aptHrUpdater: false,
        aptHrSalary: false,
        aptHrManager: false,
        aptHrEmp: false,
    };

    if (dprtList) {
        for (const d of dprtList) {
            d.dprtPath = typeof d.dprtPath === 'string' ? d.dprtPath : '';
        }
    }
    
    const loginUser = getLoginUser();
    if (loginUser.roleList) {
        for (const r of loginUser.roleList) {
            switch (r) {
                case roles.sysMaster: userRole.sysMaster = true; break;
                // case roles.sysHrMaster: userRole.sysHrMaster = true; break;

                case roles.trustMaster: 
                    userRole.trustMaster = true; 
                    userRole.trustHrManager = true;
                    userRole.trustHrSalary = true;
                    break;
                // case roles.trustHrMaster: userRole.trustHrMaster = true; break;
                case roles.trustHrUpdater: 
                    if (!dprtList) {
                        userRole.trustHrUpdater = true;
                        userRole.trustHrManager = true;
                        userRole.trustHrSalary = true;
                    } else if (dprtList && loginUser.dprtHrList) {
                        for (const dprtHr of loginUser.dprtHrList) {
                            for (const pd of dprtList) {
                                if (pd.dprtPath.startsWith(dprtHr.dprtPath)) {
                                    userRole.trustHrUpdater = true;
                                    userRole.trustHrManager = true;
                                    userRole.trustHrSalary = true;
                                    break;
                                } 
                            }
                        }    
                    }
                    break;
                case roles.trustHrSalary: 
                    if (!dprtList) {
                        userRole.trustHrSalary = true;
                    } else if (dprtList && loginUser.dprtList) {
                        for (const ud of loginUser.dprtList) {
                            if (ud.headYn === 'Y' && ud.dprtEmpSalaryYn === 'Y') {
                                for (const pd of dprtList) {
                                    if (pd.dprtPath.startsWith(ud.dprtPath)) {
                                        userRole.trustHrSalary = true;
                                        break;
                                    } 
                                }
                            }
                        }    
                    }
                    break;
                case roles.trustHrManager: 
                    if (!dprtList) {
                        userRole.trustHrManager = true;
                    } else if (dprtList && loginUser.dprtList) {
                        for (const ud of loginUser.dprtList) {
                            if (ud.headYn === 'Y' && ud.dprtEmpDetailYn === 'Y') {
                                for (const pd of dprtList) {
                                    if (pd.dprtPath.startsWith(ud.dprtPath)) {
                                        userRole.trustHrManager = true;
                                        break;
                                    } 
                                }
                            }
                        }    
                    }
                    break;
                case roles.trustHrEmp: userRole.trustHrEmp = true; break;

                case roles.aptMaster: userRole.aptMaster = true; break;
                // case roles.aptHrMaster: userRole.aptHrMaster = true; break;
                case roles.aptHrUpdater:
                    if (!dprtList) {
                        userRole.aptHrUpdater = true;
                    } else if (dprtList && loginUser.dprtHrList) {
                        for (const dprtHr of loginUser.dprtHrList) {
                            for (const pd of dprtList) {
                                if (pd.dprtPath.startsWith(dprtHr.dprtPath)) {
                                    userRole.aptHrUpdater = true;
                                    break;
                                } 
                            }
                        }    
                    }
                    break;
                case roles.aptHrSalary: 
                    if (!dprtList) {
                        userRole.aptHrSalary = true;
                    } else if (dprtList && loginUser.dprtList) {
                        for (const ud of loginUser.dprtList) {
                            if (ud.headYn === 'Y' && ud.dprtEmpSalaryYn === 'Y') {
                                for (const pd of dprtList) {
                                    if (pd.dprtPath.startsWith(ud.dprtPath)) {
                                        userRole.aptHrSalary = true;
                                        break;
                                    } 
                                }
                            }
                        }    
                    }
                    break;
                case roles.aptHrManager: 
                    if (!dprtList) {
                        userRole.aptHrManager = true;
                    } else if (dprtList && loginUser.dprtList) {
                        for (const ud of loginUser.dprtList) {
                            if (ud.headYn === 'Y' && ud.dprtEmpDetailYn === 'Y') {
                                for (const pd of dprtList) {
                                    if (pd.dprtPath.startsWith(ud.dprtPath)) {
                                        userRole.aptHrManager = true;
                                        break;
                                    } 
                                }
                            }
                        }    
                    }
                    break;
                case roles.aptHrEmp: userRole.aptHrEmp = true; break;

                default: break;
            }
        }       
    }

    // 아파트 단지 조직 경로에 대한 trustMaster, trustHrUpdater 권한 제거
    if ((userRole.trustMaster || userRole.trustHrUpdater) && dprtList) {
        for (const dp of dprtList) {
            const dprt = getDprtFromPath(dp.dprtPath);
            if (dprt && dprt.aptId) {
                userRole.trustMaster = false;
                userRole.trustHrUpdater = false;
                break;
            }
        }
    }

    // aptMaster의 경우, 경로들이 주어지면, 해당 경로에 대한 마스터 권한이 있는지 검사
    // 위탁사가 있는 단지의 경우 필요
    if (userRole.aptMaster && dprtList) {
        if (!loginUser.aptOwner) {
            userRole.aptMaster = false;
        } else if (loginUser.aptOwner.trustNo > 0) {
            userRole.aptMaster = false;
            for (const dp of dprtList) {
                const dprt = getDprtFromPath(dp.dprtPath);
                if (dprt && dprt.aptId === loginUser.aptId) {
                    userRole.aptMaster = true;
                    break;
                }
            }
        } 
    }

    userRole.updater = userRole.trustHrUpdater 
        || userRole.aptHrUpdater 
        || userRole.trustMaster 
        || userRole.aptMaster 
        ? true : false;
    userRole.salary = userRole.trustHrSalary 
        || userRole.aptHrSalary 
        || userRole.trustMaster 
        || userRole.aptMaster 
        ? true : false;
    userRole.manager = userRole.trustHrManager 
        || userRole.aptHrManager 
        || userRole.trustMaster 
        || userRole.aptMaster 
        ? true : false;
    userRole.employee = userRole.trustHrEmp || userRole.aptHrEmp ? true : false;
    return userRole;
}