import PropTypes from 'prop-types';

import { lazy } from 'react';

// project import
import lazyRetry from 'util/lazy-retry'
import Loadable from 'component/Loadable';

// project import
import ErrorBoundary from 'component/ErrorBoundary';
import ScrollTop from 'component/ScrollTop';
import ThemeCustomization from 'theme';

const MainLayout = Loadable(lazy(() => lazyRetry(() => import('layout/MainLayout'))));
const OwnerInitCheck = Loadable(lazy(() => lazyRetry(() => import('page/common/apt-owner/OwnerInitCheck'))));
const PushNotice = Loadable(lazy(() => lazyRetry(() => import('page/common/notice/PushNotice'))));

import { getRoot } from 'menu/side-menu'
import { getAuthRoot } from 'menu/login-user-menu';

/**
 * 
 * @param {string} rootId - react app이 mount될 html element id
 * @param {string} urlPath - request url의 path 부분 
 * @returns 
 */
const App = ({rootId, urlPath}) => {
    const rootList = [...getAuthRoot(), ...getRoot()];

    ////////////////////////////
    return (
        <ErrorBoundary>
            <ThemeCustomization>
                <ScrollTop>
                    {rootId === 'reactRoot' ? (
                        <MainLayout urlPath={urlPath} />
                    ) : (
                        rootList.map((item, index) => {
                            return (
                                rootId &&
                                rootId === item.root && (
                                    <>
                                        <item.component {...item.componentParams} />
                                        <PushNotice />
                                        <OwnerInitCheck />
                                    </>
                                )
                            );
                        })
                    )}
                </ScrollTop>
            </ThemeCustomization>
        </ErrorBoundary>
    );
};

App.defaultProps = {
    urlPath: '/trust',
}

App.propTypes = {
    urlPath: PropTypes.string,
    rootId: PropTypes.string,
};

export default App;
