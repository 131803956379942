import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale('ko');
dayjs.extend(customParseFormat);

// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    fromDay: dayjs().subtract(1, 'month'),
    toDay: dayjs(),
};

// ==============================|| SLICE - MENU ||============================== //

const lookupControl = createSlice({
    name: 'lookupControl',
    initialState,
    reducers: {
        fromDay(state, action) {
            state.fromDay = action.payload;
        },
        toDay(state, action) {
            state.toDay = action.payload;
        },    
    },
});

export default lookupControl.reducer;

export const { fromDay, toDay } = lookupControl.actions;
