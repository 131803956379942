import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';

// scroll bar
// import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
// import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';


// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
function renderRoot() {
    const rootId = window.amRt && window.amRt.root ? window.amRt.root : null;
    const urlPath = window.amRt && window.amRt.urlPath ? window.amRt.urlPath : null;

    if (rootId && urlPath) {
        window.amRt.root = null; // race condition에 의해서 root가 재활용되지 않도록 함

        const container = document.getElementById(rootId);
        const root = createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(
            <StrictMode>
                <ReduxProvider store={store}>
                    {/* <BrowserRouter basename="/"> */}
                    <App rootId={rootId} urlPath={urlPath} />
                    {/* </BrowserRouter> */}
                </ReduxProvider>
            </StrictMode>,
        );
    } else {
        setTimeout(renderRoot, 100);
    }
}

renderRoot();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
