// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    code: null,
    httpStatus: null,
    message: null,
};

// ==============================|| SLICE - ERROR ||============================== //

const errors = createSlice({
    name: 'error',
    initialState,
    reducers: {
        error(state, action) {
            state.code = action.payload.code ? action.payload.code : null;
            state.httpStatus = action.payload.httpStatus ? action.payload.httpStatus : null;
            state.message = action.payload.message ? action.payload.message : null;
        },
    }
});

export default errors.reducer;

export const { error } = errors.actions;
