// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    message: null,
};

// ==============================|| SLICE - MENU ||============================== //

const message = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage(state, action) {
            state.message = action.payload.message;
        },

        clearMessage(state, action) {
            state.message = null;
        },

    }
});

export default message.reducer;

export const { setMessage, clearMessage } = message.actions;
