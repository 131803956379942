/**
 * 
 * a function to retry loading a chunk to avoid chunk load error for out of date code
 *
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 * Multiple lazyRetry per route

The above code works fine if you split your code with route-based code splitting, which is what I tend to do.
But I did wonder what would happen if you had multiple React.lazy imports on a single route.
If you need to do multiple module imports on a single route/page and one is successful and the other isn't, 
the successful one could keep resetting the sessionStorage. 
It would create that endless refresh loop we talked about earlier.

In that situation, I would pass a value as a name to the lazyRetry function to identify the module.

const UserSettings = React.lazy(() => lazyRetry(() => 
import(/* webpackChunkName: "userSettings" *//* './settings'), "userSettings"));

And I would include that name in the sessionStorage key so that the page only reloads once per import fail.

const lazyRetry = function(componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // ...

You only need to make these adjustments if you have multiple React.lazy imports per route. 
I have multiple bundles loading inside my React.lazy import, 
and the original lazyRetry handles any errors as intended, no matter which chunk fails to load.
 */

export const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
        );
        // try to import the component
        componentImport()
            .then(component => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
                resolve(component);
            })
            .catch(error => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                    return window.location.reload(); // refresh the page
                }
                reject(error); // Default error behaviour as already tried refresh
            });
    });
};

/**
 * 
 */
export default lazyRetry;