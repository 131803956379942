/**
 * global object로 정의된 것들의 모음
 * 시스템 전체적으로 값의 충돌이 있으면 안되는 것들의 모음
 */
import { getTreeToList } from 'util/value';

/**
 * private 
 */

/**
 * export
 */
export const gGridAttr = {
    // window.gGridAttr 에 값이 채워지는 시점이 언제인지 명확하지 않기 때문에, 여기서 직접 설정으로 변경
    // ...window.gGridAttr,

	hdrStyle         : 'text-align:center;color:#101010; font-weight:bold; font-size:12px;',
	hdColorR		 : '#f9cfcf',
	hdrStyleR        : 'text-align:center;background-color:#f9cfcf; font-weight:bold; font-size:12px;',
	// hdrStyleR        : 'text-align:center;color:red; font-weight:bold; font-size:12px;',
	cellStyle        : 'text-align:center;font-size:11.5px;',
	colorAlt         : '#ffffff',
	// colorAlt         : '#f0f0f0',
	readOnlyColor    : '#fbfbe2', // 확인후 복사해서 아래에 사용
	readOnly         : 'background-color:#fbfbe2;',
	fixedHeight      : 28,
	rowHeight        : 22,

	totalColor       : '#d2effe', // 확인후 복사해서 아래에 사용
	subTotalColor    : 'rgba(241,166,9,0.49)',
	totalStyle       : 'background-color:#d2effe;font-weight: bold; color: #101010;',
	subTotalStyle    : 'background-color: rgba(241,166,9,0.49); font-weight: bold; color: #101010;',
	alignRight       : 'text-align:right;',
	alignLeft        : 'text-align:left;',

	errorColor		 : 'rgba(241,36,9,0.49)',
	sepBizColor		 : 'rgba(218,9,241,0.02)', // 분리검침 사업소 색상
	sepCtorColor	 : 'rgba(121,241,9,0.03)', // 분리검침 시공사 색상
	sepTransColor	 : 'rgba(241,168,9,0.04)', // 분리검침 전출자 색상
	sepOwnColor		 : 'rgba(132,132,132,0.03)', // 분리검침 소유자 색상

    mainHeight: '500px',
};
export const gSbAttr = {
    edit: {backgroundColor: gGridAttr.colorAlt,},
    view: {backgroundColor: gGridAttr.readOnlyColor,},
};
export const gSearchLimit = 10000;
export const gAprHeight = '700px';

/**
 * 
 * @param {string} [type = 'Out'] 
 * @param {string} [style] 
 * @param {string} [dataType] 
 * @param {string} [renderer] 
 * @returns {object}
 */
export const getSbGridCellStyle = (type = 'Out', style, dataType, renderer) => {
    const ga = gGridAttr;

    const cs = ga.cellStyle;
    const hs = ga.hdrStyle;
    const ro = ga.readOnly;
    const al = ga.alignLeft;
    const ar = ga.alignRight;

    let cellStyle = {fixedstyle: hs,};

    switch (type) {
        case 'Date':
            cellStyle.type = 'datepicker';
            cellStyle.typeinfo = {dateformat: 'yy-mm-dd', yearrange: 100 };
            break;
        case 'Month':
            cellStyle.type = 'datepicker';
            cellStyle.typeinfo = {dateformat: 'yy-mm-dd', calendartype: 'yearmonth', yearrange: 100 };
            break;
        case 'In':
            cellStyle.type = 'input';
            break;
        case 'InCombo':
            cellStyle.type = 'inputcombo';
            cellStyle.typeinfo = {
                // ref: belongboRef,
                label: 'label',
                value: 'value',
                oneclickedit: true,
                itemcount: 5,
                displayui: true,
                singlelistenterselect: true,
                // unselect: { label: '', value: null },
            };
            break;
        case 'Out':
            cellStyle.type = 'output';
            break;
        case 'Yn':
            cellStyle.type = 'checkbox';
            cellStyle.typeinfo = { checkedvalue: 'Y', uncheckedvalue: 'N' };
            break;
        default:
            break;
    }

    switch (style) {
        case 'Al':
            cellStyle.style = cs + al;
            break;
        case 'Ar':
            cellStyle.style = cs + ar;
            break;
        case 'Ro':
            cellStyle.style = cs + ro;
            break;
        case 'RoAl':
            cellStyle.style = cs + ro + al;
            break;
        case 'RoAr':
            cellStyle.style = cs + ro + ar;
            break;
        default:
            cellStyle.style = cs;
            break;
    }

    switch (dataType) {
        case 'Str':
            cellStyle.datatype = 'string';
            break;
        case 'Num':
            // cellStyle.datatype = 'number';
            cellStyle.format = { 
                type: 'number',
                rule: '#,###',
            };
            break;
        default:
            break;
    }

    switch (renderer) {
        case 'Edit':
            cellStyle.renderer = (objGrid, nRow, nCol, strValue, objRowData) => {
                return strValue !== 'N' ? '<i class="fa-regular fa-pen-to-square"></i>' : '';
            }
            break;
        case 'Rotate':
            cellStyle.renderer = (objGrid, nRow, nCol, strValue, objRowData) => {
                return strValue !== 'N' ? '<i class="fa-solid fa-rotate"></i>' : '';
            }
            break;
        case 'Upload':
            cellStyle.renderer = (objGrid, nRow, nCol, strValue, objRowData) => {
                return strValue !== 'N' ? '<i class="fa-solid fa-arrow-up-from-bracket"></i>' : '';
            }
            break;
        case 'Users':
            cellStyle.renderer = (objGrid, nRow, nCol, strValue, objRowData) => {
                return strValue !== 'N' ? '<i class="fa-solid fa-users"></i>' : '';
            }
            break;
        case 'View':
            cellStyle.renderer = (objGrid, nRow, nCol, strValue, objRowData) => {
                switch(strValue) {
                    case 'download':
                        return '<i class="fa-solid fa-download"></i>';
                    case 'pdf':
                        return '<i class="fa-regular fa-file-pdf"></i>';
                    default:
                        return strValue === 'Y' ? '<i class="fa-regular fa-eye"></i>' : '';
                }
            }
            break;
        default:
            break;
    }
    
    return cellStyle;
}

/**
 * @param {string[]|null} [rowheader = null] - grid row header에 표시할 항목 설정
 * @param {boolean} [rowdragmove = false] - grid row를 이동시킬 수 있는지 여부
 * @param {object|null} [paging = null] - 페이징이 필요할 경우, 페이징 관련 설정. default 설정을 사용할 경우 {} 전달
 * @param {object|null} [tree = null] - tree가 필요할 경우, 트리 관련 설정. default 설정을 사용할 경우 {} 전달
 * @param {number|null} [frozencols = null] - 그리드의 데이터 영역에서 좌측으로부터 틀고정할 열의 개수
 * @param {string|null} [mergecells = null] - 병합 타입. bycol, byrow, bycolrec, byrestriccol
 */
export const getSbGridDefaultProps = (component, {
    rowheader = null,
    rowdragmove = false,
    paging = null,
    tree = null,
    frozencols = null,
    mergecells = null,
}) => {
    if (!component) {return null;}
    const ga = gGridAttr;
    const sbGridProps = {
        parentid: getGlobalGridParentId(component),
        id: getGlobalGridId(component),
        jsonref: getGlobalGridJsonRef(component),
        emptyrecords: '데이터가 존재하지 않습니다.',
        backcoloralternate: ga.colorAlt,
        fixedrowheight: ga.fixedHeight,
        rowheight: ga.rowHeight,
        oneclickedit: true,
        width: '100%',
        rowdragmove,
    };

    if (rowheader) { sbGridProps.rowheader = rowheader;}
    if (frozencols) { sbGridProps.frozencols = frozencols;}
    if (paging) {
        sbGridProps.paging = {
            type: 'all',
            count: 10,
            size: 15,
            sorttype: 'all',
            ...paging,
        };
    }
    if (tree) {
        sbGridProps.tree = {
            col: 0,
            levelref: 'level',
            open: true,
            lock: true,
            checkbox: false,
            // checkboxexceptionlevel: [],
            // openlevel: 0,
            // checkboxchildrencheck: true,
            iconclickeventignore: true,
            ...tree,
        };
    }
    if (mergecells) {
        sbGridProps.mergecells = mergecells;
    }

    return sbGridProps;
}

///////////////////////////////////////////////////////////////
// label
//////////////////////////////////////////////////////////////
/** 
 * @param {string|null} type - label 유형. null인 경우 모든 유형
 * @returns {object[]} - {label, value} list
 */
export const getLabelList = (type = null) => {
    if (!amRt.labelList) {
        amRt.labelList = [
            {type: 'keepYear', label: '영구', value: 0},
            {type: 'keepYear', label: '1', value: 1},
            {type: 'keepYear', label: '3', value: 3},
            {type: 'keepYear', label: '5', value: 5},
            {type: 'keepYear', label: '10', value: 10},
            {type: 'keepYear', label: '15', value: 15},
            {type: 'keepYear', label: '20', value: 20},

            {type: 'secLvl', label: '기밀', value: 5},
            {type: 'secLvl', label: '대외비', value: 10},
            {type: 'secLvl', label: '일반', value: 15},

            {type: 'aprvType', label: '결재', value: 'A'},
            // {type: 'aprvType', label: '협의', value: 'C'},
            // {type: 'aprvType', label: '참조', value: 'W'},
            {type: 'aprvType', label: '열람', value: 'R'},

            {type: 'aprvTypeS', label: '결재', value: 'A'},
            {type: 'aprvTypeS', label: '협의', value: 'C'},

            {type: 'aprStatus', label: '작성중', value: 'T'},
            {type: 'aprStatus', label: '상신', value: 'S'},
            {type: 'aprStatus', label: '진행중', value: 'I'},
            {type: 'aprStatus', label: '승인', value: 'A'},
            {type: 'aprStatus', label: '반려', value: 'R'},

            {type: 'aprvStatus', label: '-', value: ''},
            {type: 'aprvStatus', label: '승인', value: 'A'},
            {type: 'aprvStatus', label: '반려', value: 'R'},
            {type: 'aprvStatus', label: '보류', value: 'D'},
        ];
    }

    const labelList = type ? [] : amRt.labelList;
    if (type) {
        for (const label of amRt.labelList) {
            if (label.type === type) {
                labelList.push({label: label.label, value: label.value});
            }
        }    
    }
    return labelList;
}

/** 
 * @param {string} type - label 유형
 * @param {string} value - value
 * @returns {string} - type, value가 일치하는 label. 일치하는 것이 없을 때는 ''
 */
export const getLabel = (type, value) => {
    const labelList = getLabelList();

    let label = '';
    for (const lb of labelList) {
        if (lb.type === type && lb.value === value) {
            label = lb.label;
            break;
        }
    }
    return label;
}

/** 
 * @param {string} type - label 유형
 * @param {string} label - label
 * @returns {string} - type, label이 일치하는 value. 일치하는 것이 없을 때는 null
 */
export const getValueOfLabel = (type, label) => {
    const labelList = getLabelList();

    let value = null;
    for (const lb of labelList) {
        if (lb.type === type && lb.label === label) {
            value = lb.value;
            break;
        }
    }
    return value;
}

///////////////////////////////////////////////////////////////
// grid combo type
//////////////////////////////////////////////////////////////
/** 
 * @param {object|null} [style = null] - grid cell style
 * @returns {object} - 결재자 결재 상태 combo type 객체
 */
export const getComboTypeAprvStatus = (style = null) => {
    if (!amRt.comboType) {amRt.comboType = {};}
    if (!amRt.comboType.aprvStatus) {
        amRt.comboType.aprvStatus = style ? style : getSbGridCellStyle('InCombo');
        amRt.comboType.aprvStatus.labelList = getLabelList('aprvStatus');
        amRt.comboType.aprvStatus.typeinfo.ref = 
            'amRt.comboType.aprvStatus.labelList';
        amRt.comboType.aprvStatus.typeinfo.itemcount = 
            amRt.comboType.aprvStatus.labelList.length;
    }
    return amRt.comboType.aprvStatus;
}

/** 
 * @param {object|null} [style = null] - grid cell style
 * @returns {object} - 결재자 유형 combo type 객체
 */
export const getComboTypeAprvType = (style = null) => {
    if (!amRt.comboType) {amRt.comboType = {};}
    if (!amRt.comboType.aprvType) {
        amRt.comboType.aprvType = style ? style : getSbGridCellStyle('InCombo');
        amRt.comboType.aprvType.labelList = getLabelList('aprvType');
        amRt.comboType.aprvType.typeinfo.ref = 
            'amRt.comboType.aprvType.labelList';
        amRt.comboType.aprvType.typeinfo.itemcount = 
            amRt.comboType.aprvType.labelList.length;
    }

    return amRt.comboType.aprvType;
}

/** 
 * @param {object|null} style - grid cell style
 * @returns {object} - keepYear combo type 객체
 */
export const getComboTypeKeepYear = (style = null) => {
    if (!amRt.comboType) {amRt.comboType = {};}
    if (!amRt.comboType.keepYear) {
        amRt.comboType.keepYear = style ? style : getSbGridCellStyle('InCombo');
        amRt.comboType.keepYear.labelList = getLabelList('keepYear');
        amRt.comboType.keepYear.typeinfo.ref = 
            'amRt.comboType.keepYear.labelList';
        amRt.comboType.keepYear.typeinfo.itemcount = 
            amRt.comboType.keepYear.labelList.length;
    }
    return amRt.comboType.keepYear;
}

/** 
 * @param {object|null} style - grid cell style
 * @returns {object} - keepYear combo type 객체
 */
export const getComboTypeSecLvl = (style = null) => {
    if (!amRt.comboType) {amRt.comboType = {};}
    if (!amRt.comboType.secLvl) {
        amRt.comboType.secLvl = style ? style : getSbGridCellStyle('InCombo');
        amRt.comboType.secLvl.labelList = getLabelList('secLvl');
        amRt.comboType.secLvl.typeinfo.ref = 
            'amRt.comboType.secLvl.labelList';
        amRt.comboType.secLvl.typeinfo.itemcount = 
            amRt.comboType.secLvl.labelList.length;
    }
    return amRt.comboType.secLvl;
}

///////////////////////////////////////////////////////////////
// login user, push notice YN
//////////////////////////////////////////////////////////////

/** 
 * @returns {object} - login user 객체
 */
export const getLoginUser = () => {
    if (!amRt.loginUser) {
        return null;
    }
    return amRt.loginUser;
}

/** 
 * @returns {object} - push notice를 할 지 여부
 */
export const getPushNoticeYn = () => {
    return amRt.pushNoticeYn;
}

///////////////////////////////////////////////////////////////
// 약어 -> 인쇄명
//////////////////////////////////////////////////////////////

/** 
 */
export const getSlrPayerCtgNm = (slrPayerCtg) => {
    let slrPayerCtgNm = '';

    switch (slrPayerCtg) {
        case 'A':
            slrPayerCtgNm = '단지';
            break;
        case 'T':
            slrPayerCtgNm = '본사';
            break;  
        case 'S':
            slrPayerCtgNm = '용역사';
            break;
        default:
            break;
    }

    return slrPayerCtgNm;
}

/** 
 */
export const getGenderNm = (gender) => {
    let genderNm = '';

    switch (gender) {
        case 'M':
            genderNm = '남자';
            break;
        case 'F':
            genderNm = '여자';
            break;
        default:
            break;
    }

    return genderNm;
}

/** 
 */
export const getVctCtgNm = (vctCtg) => {
    let vctCtgNm = '';

    switch (vctCtg) {
        case 'F':
            vctCtgNm = '종일';
            break;
        case 'A':
            vctCtgNm = '오전반차';
            break;
        case 'P':
            vctCtgNm = '오후반차';
            break;
        default:
            break;
    }

    return vctCtgNm;
}

///////////////////////////////////////////////////////////////
// global Department
//////////////////////////////////////////////////////////////

/** 
 * 조직 경로 예시
 * T:20:우리관리>동부>...
 * T:20:우리관리>A:A10023075:지와인 아파트>시설
 * A:A10023478:한일유니스빌>시설
 * @param {string} dprtPath - 조직 경로
 * @param {string|null} [trustNm = null] - 위탁사 이름
 * @param {string|null} [aptNm = null] - 단지 이름
 * @returns {string} - 관리용 문자를 제거한 조직 경로
 */
export const getDprtPathText = (dprtPath, trustNm = null, aptNm = null) => {
    const pathDelimiter = '>';
    dprtPath = !dprtPath ? '' : dprtPath;
    
    const dprt = getDprtFromPath(dprtPath);
    trustNm = trustNm ? trustNm : dprt.trustNm;
    aptNm = aptNm ? aptNm : dprt.aptNm;

    const firstDlmIdx = dprtPath.indexOf(pathDelimiter);
    const head = firstDlmIdx === -1 ? dprtPath : dprtPath.slice(0, firstDlmIdx); 
    const headWords = head.split(':');
    const headWord = headWords.length === 3 ? headWords[2] : head;
    const newHead = head.startsWith('T:')
        ? trustNm
            ? trustNm
            : headWord
        : head.startsWith('A:')
        ? aptNm
            ? aptNm
            : headWord
        : head; 

    // 최상단이 아닌 경우 A:...:...의 A: 제거
    let newOther = '';
    let other = firstDlmIdx === -1 ? '' : dprtPath.slice(firstDlmIdx + pathDelimiter.length);
    while (other) {
        const dlmIdx = other.indexOf(pathDelimiter);
        const dprtName = dlmIdx === -1 ? other : other.slice(0, dlmIdx);
        const words = dprtName.split(':');
        if (words.length === 3 && words[0] === 'A') {
            newOther = `${newOther}${pathDelimiter}${words[1]}:${words[2]}`
        } else {
            newOther = `${newOther}${pathDelimiter}${dprtName}`;
        }
        other = dlmIdx === -1 ? '' : other.slice(dlmIdx + pathDelimiter.length);
    }

    return `${newHead}${newOther}`;
}

/** 
 * 조직 경로로부터 조직 객체 조회
 * @param {string} dprtPath - 조직 경로
 * @returns {object} - 조직 객체
 */
export const getDprtFromPath = (dprtPath) => {
    if (!dprtPath) {return {};}
    if (!amRt.dprtList) {return {};}

    for (const dprt of amRt.dprtList) {
        if (dprt.path === dprtPath) {
            return dprt;
        }
    }
    return {};
}

/** 
 * 조직 경로로 하위의 단지 최상위 조직 조회
 * @param {string} dprtPath - 조직 경로
 * @returns {object[]} - 조직 객체
 */
export const getDprtAptTopFromPath = (dprtPath) => {
    if (!dprtPath) {return [];}
    if (!amRt.dprtList) {return [];}

    const dprtList = [];
    for (const dprt of amRt.dprtList) {
        if (dprt.path.startsWith(dprtPath) && dprt.aptTopYn === 'Y') {
            dprtList.push(dprt);
        }
    }
    return dprtList;
}

/** 
 * @returns {object} - department tree 최상위노드 객체
 */
export const getGlobalDprtTop = () => {
    return amRt.dprtTop;
}

/** 
 * @param {object} - department tree 최상위노드 객체
 */
export const setGlobalDprtTop = (dprt) => {
    if (!dprt) {return;}
    const checkHasApt = (dprt) => {
        let hasApt = false;
        
        if (dprt.aptTopYn === 'Y') {
            hasApt = true;
        } else {
            for (const child of dprt.children) {
                child.hasApt = checkHasApt(child);
            }

            for (const child of dprt.children) {
                if (child.hasApt) {
                    hasApt = true;
                    break;
                }
            }
        }
        return hasApt;
    };
    dprt.hasApt = checkHasApt(dprt);

    amRt.dprtTop = dprt;
    amRt.dprtList = getTreeToList(dprt);
}

/** 
 * @returns {string} - 조직 경로
 */
export const getGlobalDprtPath = () => {
    return amRt.dprtPath;
}

/** 
 * @param {string} - 조직 경로
 */
export const setGlobalDprtPath = (dprtPath) => {
    if (!dprtPath) {return;}
    amRt.dprtPath = dprtPath;
}

///////////////////////////////////////////////////////////////
// global object
//////////////////////////////////////////////////////////////

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {object} - global object 객체
 */
export const getGlobalObject = (component) => {
    if (!component) {return null;}
    if (!amRt[component]) {
        amRt[component] = {};
    }
    return amRt[component];
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - object 접근 문자열
 */
export const getGlobalObjectRef = (component) => {
    if (!component) {return null;}
    if (!amRt[component]) {
        amRt[component] = {};
    }
    return `amRt.${component}`;
}


///////////////////////////////////////////////////////////////
// global grid
//////////////////////////////////////////////////////////////
/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns 
 */
const initGlobalGrid = (component) => {
    if (!component) {return;}
    if (!amRt[component]) {
        amRt[component] = {};
    }

    if (!amRt[component].grid) {
        amRt[component].grid = {
            parentId: `gdParentHr${component}`,
            id: `gdIdHr${component}`,
            jsonRef: `amRt.${component}.grid.data`,
            data: [],
            object: null,
        };
    }
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {object|object[]} row - 추가할 grid data
 * @param {string} [position = 'start'] - data를 추가할 위치, 'start', 'end'
 * @returns 
 */
export const addGlobalGridData = (component, row = [], position = 'start') => {
    if (!component) {return;}
    initGlobalGrid(component);
    const data = row instanceof Array ? row : [row];
    const grid = getGlobalGridObject(component);
    const gridData = getGlobalGridData(component);

    const newData = [];
    switch(position) {
        case 'start':
            newData.push(...data, ...gridData);
            break;
        case 'end':
            newData.push(...gridData, ...data);
            break;
        default:
            break;
    }
    setGlobalGridData(component, newData);
    if(grid) {grid.rebuild();}

    return;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {object} sbGridProps - grid 설정 객체
 * @returns {object} - 생성된 grid 객체
 */
export const createGlobalGrid = (component, sbGridProps) => {
    if (!component || !sbGridProps) {return null;}

    try {
        initGlobalGrid(component);
        setGlobalGridObject(component, null);
        const grid = _SBGrid.create(sbGridProps);
        setGlobalGridObject(component, grid);    
        return grid;    
    } catch (e) {
        return null;
    }
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns 
 */
export const cleanGlobalGrid = (component) => {
    if (!component) {return;}
    setGlobalGridObject(component, null);
    setGlobalGridData(component, []);
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {object} - grid에 대한 정보 객체
 */
export const getGlobalGrid = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - grid가 위치할 부모 element id
 */
export const getGlobalGridParentId = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid.parentId;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - grid element id
 */
export const getGlobalGridId = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid.id;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - grid data에대한 접근 문자열
 */
export const getGlobalGridJsonRef = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid.jsonRef;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {array} - grid data array
 */
export const getGlobalGridData = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid.data;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {object} - grid object
 */
export const getGlobalGridObject = (component) => {
    if (!component) {return null;}
    initGlobalGrid(component);
    return amRt[component].grid.object;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {array} data - grid data 연결 
 * @returns 
 */
export const setGlobalGridData = (component, data) => {
    if (!component) {return;}
    initGlobalGrid(component);
    amRt[component].grid.data.length = 0;

    let rowIdx = 0;
    for (const d of data) {
        rowIdx += 1;
        d.rowIdx = rowIdx;
    }
    amRt[component].grid.data.push(...data);
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {object} grid - grid object 
 * @returns 
 */
export const setGlobalGridObject = (component, grid) => {
    if (!component) {return;}
    initGlobalGrid(component);
    try {
        if (amRt[component].grid.object) {
            amRt[component].grid.object.destroy();
            amRt[component].grid.object = null;
        }
    } catch (e) {
        console.log(`error: destroy grid ${component} :`, e);
    }
    
    amRt[component].grid.object = grid;
    // window[getGlobalGridId(component)] = grid;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {string[]} columnList - 편집 불가 처리할 컬럼 리스트 
 * @param {object[]} [exceptList = []] - 편집 불가 예외 조건 리스트
 * @param {string} exceptList[].column - 편집불가 예외 처리할 컬럼 리스트
 * @param {string} exceptList[].whereColumn - 편집 허용 조건 컬럼
 * @param {string} exceptList[].whereValue - 편집 허용 조건 값
 * @returns 
 */
export const setGlobalGridRowEditDisabled = (component, columnList, exceptList = []) => {
    const grid = getGlobalGridObject(component);
    if (!grid) {return;}
    const startRowIdx = grid.getFixedRows();
    const endRowIdx = grid.getRows();

    for (const col of columnList) {
        const colIdx = grid.getColRef(col);
        if (colIdx < 0) {continue;}

        let exCol = null;
        let exVal = null;
        for (const ex of exceptList) {
            if (ex.column === col) {
                exCol = ex.whereColumn;
                exVal = ex.whereValue;
                break;
            }
        }

        if (!exCol) {        
            grid.setCellDisabled(startRowIdx, colIdx, endRowIdx - 1, colIdx, true);
            grid.setCellStyle(
                'background-color',
                startRowIdx,
                colIdx,
                endRowIdx - 1,
                colIdx,
                gSbAttr.view.backgroundColor,
                true,
            );
        } else {
            for (let rowIdx = startRowIdx; rowIdx < endRowIdx; rowIdx += 1) {
                const row = grid.getRowData(rowIdx, false);
                if (exCol && row[exCol] === exVal) {
                    grid.setCellStyle(
                        'background-color',
                        rowIdx,
                        colIdx,
                        rowIdx,
                        colIdx,
                        gSbAttr.edit.backgroundColor,
                        true,
                    );
                } else {
                    grid.setCellDisabled(rowIdx, colIdx, rowIdx, colIdx, true);
                    grid.setCellStyle(
                        'background-color',
                        rowIdx,
                        colIdx,
                        rowIdx,
                        colIdx,
                        gSbAttr.view.backgroundColor,
                        true,
                    );
                }
            }   
        }
 
    }
    return;
}

///////////////////////////////////////////////////////////////
// global function
//////////////////////////////////////////////////////////////
/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {string} id - 함수의 id
 * @returns {function} - 함수
 */
export const getGlobalFunction = (component, id ) => {
    if (!component || !id) {return null;}
    if (!amRt[component] || !amRt[component].fn || !amRt[component].fn[id]) {
        return null;
    }        
    return amRt[component].fn[id];
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {string} id - 함수의 id
 * @returns {string} - 함수명 문자열
 */
export const getGlobalFunctionRef = (component, id ) => {
    if (!component || !id) {return null;}
    return `amRt.${component}.fn.${id}`;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {string} id - 함수의 id
 * @param {function} fn - 함수
 */
export const setGlobalFunction = (component, id, fn) => {
    if (!component || !id || !fn) {return;}
    if (!amRt[component]) {
        amRt[component] = {};
    }

    if (!amRt[component].fn) {
        amRt[component].fn = {};
    }
    amRt[component].fn[id] = fn;
}

///////////////////////////////////////////////////////////////
// global tabs
//////////////////////////////////////////////////////////////
/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns 
 */
const initGlobalTabs = (component) => {
    if (!component) {return ;}
    if (!amRt[component]) {
        amRt[component] = {};
    }

    if (!amRt[component].tabs) {
        amRt[component].tabs = {
            id: `tabsHr${component}`,
            jsonRef: `amRt.${component}.tabs.data`,
            callbackSelectRef: `amRt.${component}.tabs.callbackSelect`,
            callbackSelect: () => {},
            callbackCloseRef: `amRt.${component}.tabs.callbackClose`,
            callbackClose: () => {},
            data: [],
        };
    }
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {object} - tabs에 대한 정보 객체
 */
export const getGlobalTabs = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - grid element id
 */
export const getGlobalTabsId = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs.id;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - tabs data에대한 접근 문자열
 */
export const getGlobalTabsJsonRef = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs.jsonRef;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - tab 선택시 callback 호출 문자열
 */
export const getGlobalTabsCallbackSelectRef = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs.callbackSelectRef;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {string} - tab close callback 호출 문자열
 */
export const getGlobalTabsCallbackCloseRef = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs.callbackCloseRef;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @returns {array} - tabs data array
 */
export const getGlobalTabsData = (component) => {
    if (!component) {return null;}
    initGlobalTabs(component);
    return amRt[component].tabs.data;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {array} data - tabs data 연결 
 * @returns 
 */
export const setGlobalTabsData = (component, data) => {
    if (!component) {return;}
    initGlobalTabs(component);
    amRt[component].tabs.data = data;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {function} callback - 탭 선택시 실행할 함수 
 * @returns 
 */
export const setGlobalTabsCallbackSelect = (component, callback) => {
    if (!component) {return;}
    initGlobalTabs(component);
    amRt[component].tabs.callbackSelect = callback;
}

/** 
 * @param {string} component - 이 함수를 호출한 component 
 * @param {function} callback - 탭 선택시 실행할 함수 
 * @returns 
 */
export const setGlobalTabsCallbackClose = (component, callback) => {
    if (!component) {return;}
    initGlobalTabs(component);
    amRt[component].tabs.callbackClose = callback;
}
