// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    title: '',
};

// ==============================|| SLICE - MENU ||============================== //

const header = createSlice({
    name: 'header',
    initialState,
    reducers: {
        title(state, action) {
            state.title = action.payload.title;
        },
    }
});

export default header.reducer;

export const { title } = header.actions;
