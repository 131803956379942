import { lazy } from 'react';
 
// project import
import lazyRetry from 'util/lazy-retry'
import Loadable from 'component/Loadable';

import { getLoginUser } from 'config/global';
import { getUserRole,} from 'config/user-role';

/////////////////////////////////////////////////////////
// service page
/////////////////////////////////////////////////////////
// Approval
const AprAbsence = Loadable(lazy(() => lazyRetry(() => import('page/approval/absence/Absence'))));
const AprApproval = Loadable(lazy(() => lazyRetry(() => import('page/approval/approval/Approval'))));
// const AprApprovalDisuse = Loadable(lazy(() => lazyRetry(() => import('page/approval/approval/ApprovalDisuse'))));
const AprApprovalOpen = Loadable(lazy(() => lazyRetry(() => import('page/approval/approval/ApprovalOpen'))));
const AprApprover = Loadable(lazy(() => lazyRetry(() => import('page/approval/approver/Approver'))));
const AprCategory = Loadable(lazy(() => lazyRetry(() => import('page/approval/category/Category'))));
const AprTemplate = Loadable(lazy(() => lazyRetry(() => import('page/approval/template/Template'))));

// common
const Apt = Loadable(lazy(() => lazyRetry(() => import('page/common/apt/Apt'))));
const Owner = Loadable(lazy(() => lazyRetry(() => import('page/common/apt-owner/Owner'))));
const Company = Loadable(lazy(() => lazyRetry(() => import('page/common/company/Company'))));
const Notice = Loadable(lazy(() => lazyRetry(() => import('page/common/notice/Notice'))));
const Trust = Loadable(lazy(() => lazyRetry(() => import('page/common/trust/Trust'))));

// HR
const Appoint = Loadable(lazy(() => lazyRetry(() => import('page/hr/appointment/Appoint'))));
const Attend = Loadable(lazy(() => lazyRetry(() => import('page/hr/attendance/Attend'))));
const Bonus = Loadable(lazy(() => lazyRetry(() => import('page/hr/bonus/Bonus'))));
const Conf = Loadable(lazy(() => lazyRetry(() => import('page/hr/conf/Conf'))));
const ContractMeta = Loadable(lazy(() => lazyRetry(() => import('page/hr/contract/ContractMeta'))));
const Department = Loadable(lazy(() => lazyRetry(() => import('page/hr/department/Department'))));
const DprtEmp = Loadable(lazy(() => lazyRetry(() => import('page/hr/department/DprtEmp'))));
const FamilyEvent = Loadable(lazy(() => lazyRetry(() => import('page/hr/family-event/FamilyEvent'))));
const Guarantee = Loadable(lazy(() => lazyRetry(() => import('page/hr/guarantee/Guarantee'))));
const OverWork = Loadable(lazy(() => lazyRetry(() => import('page/hr/attendance/OverWork'))));
const ProxyUser = Loadable(lazy(() => lazyRetry(() => import('page/user/ProxyUser'))));
const Punishment = Loadable(lazy(() => lazyRetry(() => import('page/hr/reward-punishment/Punishment'))));
const Reward = Loadable(lazy(() => lazyRetry(() => import('page/hr/reward-punishment/Reward'))));
const Salary = Loadable(lazy(() => lazyRetry(() => import('page/hr/salary/Salary'))));
const SalaryContract = Loadable(lazy(() => lazyRetry(() => import('page/hr/salary-contract/SalaryContract'))));
const SocInsurance = Loadable(lazy(() => lazyRetry(() => import('page/hr/soc-insurance/SocInsurance'))));
const SocIsrAdj = Loadable(lazy(() => lazyRetry(() => import('page/hr/soc-isr-adj/SocIsrAdj'))));
const ResignPay = Loadable(lazy(() => lazyRetry(() => import('page/hr/resign-pay/ResignPay'))));
const Tax = Loadable(lazy(() => lazyRetry(() => import('page/hr/tax/Tax'))));
const TrainingMeta = Loadable(lazy(() => lazyRetry(() => import('page/hr/training/TrainingMeta'))));
const Vacation = Loadable(lazy(() => lazyRetry(() => import('page/hr/vacation/Vacation'))));
const VacationUse = Loadable(lazy(() => lazyRetry(() => import('page/hr/vacation/VacationUse'))));
const VacationUseSvcEmp = Loadable(lazy(() => lazyRetry(() => import('page/hr/vacation/VacationUseSvcEmp'))));
const OmApproval = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/approval/Approval'))));
const OmAssetTool = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/asset/AssetTool'))));
const OmAssetSupply = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/asset/AssetSupply'))));
const OmComplaint = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/complaint/Complaint'))));
const OmContract = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/contract/Contract'))));
const OmEContract = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/contract/EContract'))));
const OmEsaApproval = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/esa-action/Approval'))));
const OmEsaAction = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/esa-action/Action'))));
const OmFmsItem = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/kapt/FmsItem'))));
const OmKaptLog = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/kapt/KaptLog'))));
const OmLtrReport = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/ltr-plan/LtrReport'))));
const OmEmployee = Loadable(lazy(() => lazyRetry(() => import('page/open-manual/personnel/Employee'))));
const User = Loadable(lazy(() => lazyRetry(() => import('page/user/User'))));


/**
 * 
 */
const getSideMenuTree = () => {
    const loginUser = getLoginUser();
    if (!loginUser) {return [];}

    const userRole = getUserRole();
    const sideMenuTree = [];
    const belongCtg = loginUser.aptId ? 'A' : 'T';
    const isReady = loginUser.trustNo || (loginUser.aptId && loginUser.aptOwner) ? true : false;
    const isAptWithTrust = loginUser.aptId && loginUser.aptOwner && loginUser.aptOwner.trustNo ? true : false;

    if (userRole.sysMaster) {
        sideMenuTree.push({
            text: '오픈매뉴얼관리자',
            children: [
                {
                    text: '설정',
                    root: 'rtHrConfSys',
                    component: Conf,
                    componentParams: { belongCtg: 'Sys' },
                },
                { text: '단지', root: 'rtHrMgmtSysApt', component: Apt },
                { text: '위탁사', root: 'rtHrMgmtSysTrust', component: Trust },
                {
                    text: '사용자',
                    root: 'rtHrMgmtSysUser',
                    component: User,
                    componentParams: { belongCtg: 'Sys' },
                },
                { text: '사용자대행', root: 'rtHrMgmtSysProxy', component: ProxyUser },
                {
                    text: '공지사항',
                    root: 'rtHrMgmtSysNotice',
                    component: Notice,
                    componentParams: { belongCtg: 'Sys' },
                },
                {
                    text: '간이세액표',
                    root: 'rtHrSysTax',
                    component: Tax,
                    componentParams: { belongCtg: 'Sys' },
                },
            ],    
        });
    }

    sideMenuTree.push(
        { 
            text: '시스템초기화', 
            hidden: isReady || !(userRole.aptMaster || userRole.aptHrUpdater),
            root: 'rtHrAptOwnerInit',
            open: true, 
            component: Owner,
        },
        {
            text: '시스템관리',
            hidden: !isReady || !userRole.updater,
            children: [
                { 
                    text: '관리주체', 
                    hidden: !(userRole.aptMaster || userRole.aptHrUpdater),
                    root: 'rtHrAptOwner',
                    open: true, 
                    component: Owner,
                },
                {
                    text: '설정',
                    root: 'rtHrConf',
                    open: true,
                    component: Conf,
                    componentParams: { belongCtg },
                },
                {
                    text: '사용자',
                    root: 'rtHrMgmtUser',
                    component: User,
                    componentParams: { belongCtg },
                },
                {
                    text: '조직',
                    root: 'rtHrMgmtDprt',
                    component: Department,
                    componentParams: { belongCtg },
                },
                {
                    text: '공지사항(APT Manual)',
                    root: 'rtHrMgmtNotice',
                    component: Notice,
                    componentParams: { belongCtg },
                },
                {
                    text: '거래처',
                    root: 'rtHrMgmtCompany',
                    component: Company,
                    componentParams: { belongCtg },
                },
                { text: '간이세액표', root: 'rtHrTax', component: Tax },
            ],
        },
        {
            text: isAptWithTrust ? '전자결재(위탁사)' : '전자결재',
            hidden: true,
            // hidden: !isReady || !userRole.employee || (loginUser.aptId && !isAptWithTrust),
            children: [
                { text: '기안함', root: 'rtAprApproval', component: AprApproval, componentParams: { belongCtg,}, },
                { text: '결재함', root: 'rtAprAprvAC', component: AprApprover, componentParams: { belongCtg, aprvType: 'AC'}, },
                // { text: '참조함', root: 'rtAprAprvW', component: AprApprover, componentParams: { belongCtg, aprvType: 'W'}, },
                { text: '열람함', root: 'rtAprAprvR', component: AprApprover, componentParams: { belongCtg, aprvType: 'R'}, },
                { text: '공개함', root: 'rtAprApprovalOpen', component: AprApprovalOpen, componentParams: { belongCtg,}, },
                { text: '부재설정', root: 'rtAprAbsence', component: AprAbsence, componentParams: { belongCtg, isUpdater: userRole.updater }, },
                { 
                    text: '관리',
                    hidden: !userRole.updater,
                    children: [
                        { text: '결재분류', root: 'rtAprCategory', component: AprCategory, componentParams: { belongCtg }, },
                        { text: '결재양식', root: 'rtAprTemplate', component: AprTemplate, componentParams: { belongCtg }, },
                        // { text: '폐기', root: 'rtAprApprovalDisuse', component: AprApprovalDisuse, componentParams: { belongCtg,}, },
                    ],
                },
            ],
        },
        {
            text: '직원신상',
            hidden: !isReady || !userRole.employee,
            root: 'rtHrDprtEmp',
            component: DprtEmp,
            componentParams: { belongCtg, isUpdater: userRole.updater },
        },
        {
            text: '직원발령',
            hidden: !isReady || !userRole.updater,
            children: [
                {
                    text: '입사',
                    root: 'rtHrAppointJoin',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustJoin' : 'AptJoin'},
                },
                {
                    text: '퇴직',
                    root: 'rtHrAppointResign',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustResign' : 'AptResign' },
                },
                // { text: '휴직' },
                {
                    text: '조직원변경',
                    root: 'rtHrApppointDprtJoin',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustDprtJoin' : 'AptDprtJoin' },
                },
                {
                    text: '조직원해제',
                    root: 'rtHrAppointDprtEnd',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustDprtEnd' : 'AptDprtEnd' },
                },
                {
                    text: '직위(직책)',
                    root: 'rtHrAppointPos',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustPos' : 'AptPos' },
                },
                {
                    text: '직무(직종)',
                    root: 'rtHrAppointJob',
                    component: Appoint,
                    componentParams: { action: belongCtg === 'T' ? 'TrustJob' : 'AptJob' },
                },
            ],
        },
        {
            text: '직원관리',
            hidden: !isReady || !(userRole.updater || userRole.salary || userRole.manager),
            children: [
                {
                    text: '계약',
                    hidden: !(userRole.updater || userRole.salary),
                    root: 'rtHrContractMeta',
                    component: ContractMeta,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '교육/훈련',
                    root: 'rtHrTrainingMeta',
                    component: TrainingMeta,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '경조사',
                    root: 'rtHrFamilyEvent',
                    component: FamilyEvent,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '신원보증',
                    root: 'rtHrGurantee',
                    component: Guarantee,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                // { text: '증명서' },
                {
                    text: '포상',
                    root: 'rtHrReward',
                    component: Reward,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '징계',
                    root: 'rtHrPunishment',
                    component: Punishment,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
            ],
        },
        {
            text: '근태/휴가',
            hidden: !isReady || !(userRole.updater || userRole.salary || userRole.manager),
            children: [
                {
                    text: '근태',
                    root: 'rtHrAttendance',
                    component: Attend,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '초과근무',
                    root: 'rtHrOverWork',
                    component: OverWork,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '휴가부여',
                    root: 'rtHrVacation',
                    component: Vacation,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '휴가사용',
                    root: 'rtHrVacationUse',
                    component: VacationUse,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '휴가사용(용역직원)',
                    root: 'rtHrVacationUseSvcEmp',
                    component: VacationUseSvcEmp,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
            ],
        },
        {
            text: '급여',
            hidden: !isReady || !(userRole.updater || userRole.salary),
            children: [
                {
                    text: '급여지급',
                    hidden: !userRole.updater,
                    root: 'rtHrSalary',
                    component: Salary,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '급여계약',
                    root: 'rtHrSalaryContract',
                    component: SalaryContract,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '상여금',
                    root: 'rtHrBonus',
                    component: Bonus,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '사회보장보험',
                    hidden: !userRole.updater,
                    root: 'rtHrSocInsurance',
                    component: SocInsurance,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '사회보장보험정산',
                    hidden: !userRole.updater,
                    root: 'rtHrSocIsrAdj',
                    component: SocIsrAdj,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
                {
                    text: '퇴직금',
                    hidden: !userRole.updater,
                    root: 'rtHrResignPay',
                    component: ResignPay,
                    componentParams: { belongCtg, isUpdater: userRole.updater },
                },
            ],
        },
        {
            text: '단지정보조회',
            hidden: !(userRole.trustMaster || userRole.trustHrSalary || userRole.trustHrManager),
            children: [
                { text: '세대민원', root: 'rtOmComplaint', component: OmComplaint },
                {
                    text: '계약',
                    children: [
                        { text: '수기계약', root: 'rtOmContract', component: OmContract },
                        { text: '전자계약', root: 'rtOmEContract', component: OmEContract },
                    ],
                },
                { text: '결재', root: 'rtOmApproval', open: true, component: OmApproval },
                {
                    text: '안전교육',
                    children: [
                        { text: '결재현황', root: 'rtOmEsaApproval', component: OmEsaApproval },
                        {
                            text: '법정계획',
                            root: 'rtOmEsaMgmtPlan',
                            component: OmEsaAction,
                            componentParams: { category: '법정계획' },
                        },
                        {
                            text: '법정검사',
                            root: 'rtOmEsaLegalCheck',
                            component: OmEsaAction,
                            componentParams: { category: '법정검사' },
                        },
                        {
                            text: '안전진단',
                            root: 'rtOmEsaSafetyCheck',
                            component: OmEsaAction,
                            componentParams: { category: '안전진단' },
                        },
                        {
                            text: '외부교육',
                            root: 'rtOmEsaLegalEdu',
                            component: OmEsaAction,
                            componentParams: { category: '외부교육' },
                        },
                        {
                            text: '자체교육',
                            root: 'rtOmEsaSelfEdu',
                            component: OmEsaAction,
                            componentParams: { category: '자체교육' },
                        },
                        {
                            text: '등록/신고/보고',
                            root: 'rtOmEsaRegistration',
                            component: OmEsaAction,
                            componentParams: { category: '등록/신고/보고' },
                        },
                        {
                            text: '공개/공고',
                            root: 'rtOmNoti',
                            component: OmEsaAction,
                            componentParams: { category: '공개/공고' },
                        },
                    ],
                },
                {
                    text: '장기수선계획',
                    children: [
                        // { text: '적립-사용 현황', root: 'rtOmLtrProvision', component: OmEsaApproval, },
                        // { text: '추이분석', root: 'rtOmLtrFlow', component: OmEsaAction, componentParams: { category: '법정계획' }},
                        // { text: '수선 계획-적립 총괄표', root: 'rtOmLtrAllTable', component: OmEsaAction, componentParams: { category: '법정검사' }},
                        { text: '검토-조정 보고서', root: 'rtOmLtrReport', component: OmLtrReport },
                    ],
                },
                {
                    text: '시설유지관리',
                    children: [
                        { text: '시설현황', root: 'rtOmFmsItem', component: OmFmsItem },
                        { text: '시설공사이력(K-APT)', root: 'rtOmKaptLog', component: OmKaptLog },
                    ],
                },
                {
                    text: '자산',
                    children: [
                        {
                            text: '공기구',
                            root: 'rtOmAssetTool',
                            component: OmAssetTool,
                            componentParams: { category: '공기구' },
                        },
                        {
                            text: '비품',
                            root: 'rtOmAssetFixture',
                            component: OmAssetTool,
                            componentParams: { category: '비품' },
                        },
                        { text: '소모품', root: 'rtOmAssetSupply', component: OmAssetSupply },
                    ],
                },
                { text: '직원', hidden: true, root: 'rtOmEmployee', component: OmEmployee },
            ],
        },
    // {
    //     menuTarget: 'T',
    //     text: '나의 정보',
    //     children: [
    //         { text: '사원찾기', root: 'rtHrConfSys', component: ConfSys },
    //         { text: '인사기본', root: 'rtHrConfSys', component: ConfSys },
    //         { text: '계약', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '급여', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '사회보장보험', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '근태', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '휴가', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '교육/훈련', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '상벌', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '증명서', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '경조사', root: 'rtHrMgmtSysApt', component: MgmtSysApt },
    //         { text: '발령', root: 'rtHrConfSys', component: ConfSys },
    //     ],
    // },
    );
    return sideMenuTree;
}

/**
 * 
 */
export function getSideMenu (urlPath = '/trust', pid, parentName, sideMenuChildren) {
    const menuList = [];

    if (typeof pid === 'undefined') {
        pid = 'mHr';
    }

    if (typeof sideMenuChildren === 'undefined') {
        sideMenuChildren = getSideMenuTree();
    }

    let order = 1;
    if (sideMenuChildren && sideMenuChildren.length > 0) {
        for (const smc of sideMenuChildren) {
            if (smc.hidden) {continue;}
            const id = `${pid}_${order}`;

            if (smc.children) {
                const ml = getSideMenu(urlPath, id, smc.text, smc.children );
                menuList.push(...ml);
            }

            menuList.push({
                id,
                pid: `${pid}`,
                order,
                text: smc.text,
                targetname: smc.root ? `menu_tab_cont_${pid}_${order}` : undefined,
                link: smc.root ? `${urlPath}?rr=${smc.root}` : undefined,
                linkkey: smc.root ? `${urlPath}?rr=${smc.root}` : undefined,
                tabName: parentName ? `${parentName}/${smc.text}` : smc.text,
                open: smc.open,
            });

            order += 1;
        }
    }

    return menuList;
}

/**
 * 
 */
export function getRoot (sideMenuChildren) {
    const rootList = [];

    if (typeof sideMenuChildren === 'undefined') {
        sideMenuChildren = getSideMenuTree();
    }

    if (sideMenuChildren && sideMenuChildren.length > 0) {
        for (const smc of sideMenuChildren) {
            if (smc.hidden) {continue;}
            if (smc.root) {
                rootList.push({
                    root: smc.root,
                    component: smc.component,
                    componentParams: smc.componentParams,
                })
            }

            if (smc.children) {
                const rtl = getRoot(smc.children);
                rootList.push(...rtl);
            }
        }
    }

    return rootList;
}

/**
 * 
 */
export default getSideMenu;