// third-party
import { combineReducers } from 'redux';

// project import
import auth from './auth';
import apt from './apt';
import error from './error';
import header from './header';
import lookupControl from './lookup-control';
import menu from './menu';
import message from './message';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ 
    auth, 
    apt, 
    error, 
    header, 
    lookupControl,
    menu, 
    message,
 });

export default reducers;
