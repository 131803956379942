// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isLogin: false,
    user: null,
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            state.isLogin = action.payload.isLogin;
            state.user = action.payload.user;
        },

        logout(state, action) {
            state.isLogin = false;
            state.user = null;
        },

    }
});

export default auth.reducer;

export const { login, logout } = auth.actions;
