// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    aptId: null,
    name: '',
    address: '',
    doroAddress: '',
    category: '',
    totalCount: '', // 세대수
};

// ==============================|| SLICE - MENU ||============================== //

const apt = createSlice({
    name: 'Apt',
    initialState,
    reducers: {
        select(state, action) {
            state.aptId = action.payload.aptId;
            state.name = action.payload.name;
            state.address = action.payload.address;
            state.doroAddress = action.payload.doroAddress;
            state.category = action.payload.category;
            state.totalCount = action.payload.totalCount;
        },
    }
});

export default apt.reducer;

export const { select } = apt.actions;
