import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.locale('ko');
dayjs.extend(customParseFormat);


/**
 * 사용자와 주고 받는 값의 검증 및 변환 유틸
 */

/**
 * 주어진 날짜에 추가된 날수 반환
 * @param {string} date - 기준 날짜. YYYY-MM-DD 형식
 * @param {number} interval - 추가될 날짜
 * @param {string} [unit = 'day'] - 추가 단위. day, week, month, quarter, year
 * @returns {string}
 */
export function getDateAddString(date, interval, unit = 'day') {
    const dateStr = dayjs(date, 'YYYY-MM-DD').add(interval, unit).format('YYYY-MM-DD');

    return dateStr;
}

/**
 * 두 날짜의 차이 계산
 * @param {string} startD - 시작 날짜. YYYY-MM-DD 형식
 * @param {string} endD - 종료 날짜. YYYY-MM-DD 형식
 * @param {string} unit - 차이 계산 단위
 * @returns {string}
 */
export function getDateDiff(fromD, toD, unit='day') {
    if(!fromD || !toD) {return null;}

    let diff = null;
    const from = dayjs(fromD, 'YYYY-MM-DD');
    const to = dayjs(toD, 'YYYY-MM-DD');

    switch (unit) {
        case 'd':
        case 'day':
            diff = to.diff(from, 'day');
            break;
        case 'w':
        case 'week':
            diff = to.diff(from, 'week');
            break;
        case 'M':
        case 'month':
            diff = to.diff(from, 'month');
            break;
        case 'Q':
        case 'quarter':
            diff = to.diff(from, 'quarter');
            break;
        case 'y':
        case 'year':
            diff = to.diff(from, 'year');
            break;
        default:
            break;
    }
    return diff;
}

/**
 * 정규환된 날짜 문자열 만들기
 * @param {string} date - 정규화된 날짜 문자열로 변경할 원본
 * @param {string} [format = 'YYYY-MM-DD'] - 종료 날짜. YYYY-MM-DD 형식
 * @returns {string} - format에 맞지 않을 경우 ''
 */
export function getDateNormalizedString(date, format='YYYY-MM-DD') {
    if(!date) {return '';}

    let norm = '';
    switch (format) {
        case 'YYYY-MM-DD':
        case 'yyyy-mm-dd': 
            {
                const words = date.split('-');
                const year = words[0] ? Number(words[0]) : null;
                const month = words[1] ? Number(words[1]) : null;
                const day = words[2] ? Number(words[2]) : null;
                if (
                    year && month && day &&
                    1900 < year && year < 2200 &&
                    0 < month && month < 13 &&
                    0 < day && day < 32
                ) {
                    norm = `${year}-${month < 10 ? '0' + month : month}-${
                        day < 10 ? '0' + day : day}`;
                }
            }
            break;
        case 'YYYY-MM':
        case 'yyyy-mm':
            {
                const words = date.split('-');
                const year = words[0] ? Number(words[0]) : null;
                const month = words[1] ? Number(words[1]) : null;
                if (
                    year && month &&
                    1900 < year && year < 2200 &&
                    0 < month && month < 13
                ) {
                    norm = `${year}-${month < 10 ? '0' + month : month}`;
                }
            }
            break;
        default:
            break;
    }
    return norm;
}

/**
 * 정수값 가져오기
 * @param {*} value
 * @param {*} [defaultValue] - value가 integer로 변환되지 않을 경우 사용할 기본 값
 * @returns {(number|null)} - integer로 변경할 수 없을 경우 null
 */
export function getInteger(value, defaultValue) {
    let number = Number(value);
  
    if (isNaN(number) && defaultValue !== undefined && defaultValue !== null) {
        number = Number(defaultValue);
    }
    if (isNaN(number)) {
      return null;
    }
  
    return number;
}
  
/**
 * masking 한 문자열 가져오기
 * @param {*} value - 모든 값을 문자열로 취급
 * @param {number} [keepLastLength = 0] - masking 하지 않을 문자열 끝에서 부터 문자 수
 * @param {string} [padChar = '*'] - masking에서 사용할 문자. padChar[0]만 사용
 * @param {number} [totalLength = 0] - 최종 문자열 길이. 값이 없거나 0인 경우 value의 길이와 같게함
 * @returns {string}
 */
export function getMaskedString(
    value,
    keepLastLength = 0,
    padChar = '*',
    totalLength = 0,
) {
    let valueStr = '';
    let keepStr = '';
    let padStr = '';
    let padLength = 0;

    if (value !== undefined && value !== null) {
        valueStr = `${value}`;
    }
    if (!(totalLength > 0)) {
        totalLength = valueStr.length;
    }
    if (keepLastLength > 0) {
        keepStr = valueStr.slice(-1 * keepLastLength);
    }
    padLength = totalLength - keepStr.length;
    for (let idx = 0; idx < padLength; idx += 1) {
        padStr += padChar[0];
    }
    return padStr + keepStr;
}

/**
 * 정규화된 전화번호 문자열 가져오기
 * @param {string} phoneStr
 * @returns {string}
 */
export function getNormalizedPhoneString(phoneStr) {
    const phoneNum = phoneStr ? phoneStr.replace(/\D/g, '') : '';

    const result = phoneNum.length > 7 
        ? phoneNum.replace(
            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
            '$1-$2-$3',)
        : phoneNum
        ;

    return result;
}

/**
 * 현재 날짜의 문자열 반환
 * @returns {string}
 */
export function getNowString() {
    const nowStr = dayjs().format('YYYY-MM-DD');

    return nowStr;
}

/**
 * 숫자 값을 3 자리 단위로 comma를 추가
 * value가 숫자가 아닌 경우 value 리턴
 * @param {number} value 
 * @returns 
 */
export function getNumberWithComma(value) {
    if (!isNumber(value)) {
        if (value) {
            return value;
        } else {
            return '';
        }
    }

    const numberStr = '' + value;
    let [intStr, decimalStr] = numberStr.split('.');
    const commaCount = Math.floor((intStr.length - 1) / 3);
    let intWithComma = '';
    for (let idx  = 0; idx < commaCount; idx++) {
        const length = intStr.length;
        const strCut = intStr.substring(length - 3, length);
        intStr = intStr.substring(0, length - 3);
        intWithComma = `,${strCut}${intWithComma}`;
    }
    intWithComma = `${intStr}${intWithComma}`;
 
    return decimalStr ? `${intWithComma}.${decimalStr}` : intWithComma;
}

/**
 * value에 값이 없으면 기본값 조회
 * @param {*} value
 * @param {*} [defaultValue] - value에 값이 없을 경우 사용할 기본 값
 * @returns {(*|null)}
 */
export function getParam(value, defaultValue) {
    let param = null;

    if (value !== undefined && value !== null && value !== '') {
        param = value;
    }
    if (
        param === null &&
        defaultValue !== undefined &&
        defaultValue !== null &&
        defaultValue !== ''
    ) {
        param = defaultValue;
    }

    return param;
}

/**
 * 랜덤 문자열
 * @param {number} num - 문자열 길이
 * @returns {string} - 랜덤 문자열
 */

export function getRandomString(num) {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
}

/**
 * tree를 리스트로 변경
 * @param {object} node - 트리 노드
 * @param {object[]} node.children - 노드의 자식들
 * @returns {object[]} - 리스트로 변경된 것
 */

export function getTreeToList(node, level = 1) {
    if (!node || !node.children || !node.children.length === 0) {
        return [];
    }

    const nodeList = [{...node, level}];
    for (const child of node.children) {
        const childList = getTreeToList(child, level + 1);
        nodeList.push(...childList);
    }

    return nodeList;
}

/**
 * 'Y' 또는 'N' 값 가져오기
 * @param {*} value
 * @param {*} [defaultValue] - value가 'Y' 또는 'N'으로 변경되지 않을 경우 사용할 기본 값
 * @returns {('Y'|'N'|null)} - 'Y' 또는 'N'으로 변경할 수 없을 경우 null
 */
export function getYn(value, defaultValue) {
    let yn = null;

    switch (value) {
        case 'Y':
        case 'y':
            yn = 'Y';
            break;
        case 'N':
        case 'n':
            yn = 'N';
            break;
        default:
            yn = null;
            break;
    }

    if (yn == null) {
        switch (defaultValue) {
            case 'Y':
            case 'y':
                yn = 'Y';
                break;
            case 'N':
            case 'n':
                yn = 'N';
                break;
            default:
                yn = null;
                break;
            }
    }
    return yn;
}


/**
 * 숫자인지 판단
 * @param {value} value 
 * @returns {boolean}
 */
export function isNumber(value) {
	const checkValue = '' + value;
	if (isNaN(checkValue) || checkValue === '') {
		return false;
    } else {
        return true;
    }
}

/**
 * 생일 유효성 검사
 * @param {string} year
 * @param {string} [month = null]
 * @param {string} [day = null]
 * @returns {boolean}
 */
export function isValidBirthDate(year, month = null, day = null) {
    if (!/^19[0-9][0-9]|20\d{2}$/.test(year)) {
        return false;
    }
    if (month && !/^(0[0-9]|1[0-2])$/.test(month)) {
        return false;
    }
    if (day && !/^(0[1-9]|[1-2][0-9]|3[0-1])$/.test(day)) {
        return false;
    }

    return true;
}
  
/**
 * 이메일 유효성 검사
 * @param {string} email
 * @returns {boolean}
 */
export function isValidEmail(email) {
    return /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/.test(email);
}

/**
 * 전화번호 유효성 검사
 * @param {string} phone
 * @returns {boolean}
 */
export function isValidPhone(phone) {
    return /^(^02.{0}|^01.{1}|[0-9]{3})-{0,1}([0-9]{3,4})-{0,1}([0-9]{4})$/.test(phone);
}
  
/**
 * 비밀번호 유효성 검사. 숫자, 특수문자, 영문자 각 1회 이상 포함, 8자에서 25자까의 문자열
 * @param {string} password
 * @returns {object}
 */
export function testPassword(passWord) {
    const passWordStatus = {};

    if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(passWord)) {
        passWordStatus.status = 0;
        passWordStatus.message =
        '숫자, 특수문자, 영문자 각 1회 이상 포함, 8자에서 25자까의 문자가 필요합니다.';
    } else if (passWord.length < 10) {
        passWordStatus.status = 1;
        passWordStatus.message = '';
    } else if (passWord.length < 12) {
        passWordStatus.status = 2;
        passWordStatus.message = '';
    } else {
        passWordStatus.status = 3;
        passWordStatus.message = '';
    }

    return passWordStatus;
}

